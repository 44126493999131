import * as user from './modules/user'
import * as application from './modules/application'
import * as settings from './modules/settings'
import * as admin from './modules/admin'

export const useService = () => {
  return {
    user,
    application,
    settings,
    admin, // TODO: Look into lazy loading this
  }
}
