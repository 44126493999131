import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// https://github.com/firebase/firebase-js-sdk/issues/388#issuecomment-355785935
import { FirebaseApp } from '@firebase/app-types'
import { FirebaseAuth } from '@firebase/auth-types'

const firebaseApp = firebase.default.initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
})

// Products
const auth = firebaseApp.auth()
const db = firebaseApp.firestore()
const storage = firebaseApp.storage()

// Database
const usersCollection = db.collection('users')
const applicationsCollection = db.collectionGroup('applications')
const settingsCollection = db.collection('settings')

export {
  storage,
  db,
  auth,
  usersCollection,
  applicationsCollection,
  settingsCollection,
}
