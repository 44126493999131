import { auth, usersCollection, db } from '../../firebase/firebase'
import * as firebase from 'firebase/app'

enum Fault {
  noUser = 'No user logged in',
  userDetailsMissing = 'User details is missing',
}

const HOST = import.meta.env.DEV
  ? 'http://localhost:3000'
  : 'https://portal.langaards-stiftelse.no' // TODO: This does not work in production

export const signIn = async (email: string) => {
  await auth.sendSignInLinkToEmail(email, {
    url: `${HOST}/signin/verify`,
    handleCodeInApp: true,
  })
}

export const signInWithPassword = async (email: string, password: string) => {
  await auth.signInWithEmailAndPassword(email, password)
}

export const signInWithGoogle = async () => {
  const provider = new firebase.default.auth.GoogleAuthProvider()
  //TODO: provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
  await auth.signInWithPopup(provider)
}

export const confirmSignInLink = (url: string): Boolean => {
  return auth.isSignInWithEmailLink(url)
}

export const completeSignIn = async (email: string, url: string) => {
  await auth.signInWithEmailLink(email, url)
}

export const logout = async () => {
  await auth.signOut()
}

export const fetchDetails = async (): Promise<object> => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const result = await usersCollection.doc(userId).get()
  if (!result.exists) throw Error(Fault.userDetailsMissing)
  return result.data()!
}

export const saveDetails = async (details: object) => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)
  await usersCollection.doc(userId).set(details)
}

export const currentUser = async () => {
  return auth.currentUser
}

export const isAdmin = async (): Promise<boolean> => {
  try {
    await db.collection('settings').doc('admin').get()
    return true
  } catch {
    // Cannot read admin doc. User is not a admin.
    return false
  }
}
