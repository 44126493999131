import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase/firebase'
import { useService } from '../service/service'

const SigninBase = () => import('../pages/signin/Base.vue')
const Signin = () => import('../pages/signin/Signin.vue')
const SigninCheckEmail = () => import('../pages/signin/CheckEmail.vue')
const SigninVerify = () => import('../pages/signin/Verify.vue')
const SigninUserDetails = () => import('../pages/signin/UserDetails.vue')

const UserBase = () => import('../pages/user/Base.vue')
const User = () => import('../pages/user/Profile.vue')
const UserApplication = () => import('../pages/user/Application.vue')
const UserApplicationSummary = () =>
  import('../pages/user/ApplicationSummary.vue')

const AdminBase = () => import('../pages/admin/Base.vue')
const AdminLogin = () => import('../pages/admin/Login.vue')
const AdminDashboard = () => import('../pages/admin/Dashboard.vue')
const AdminUsers = () => import('../pages/admin/Users.vue')
const AdminSettings = () => import('../pages/admin/Settings.vue')

const SiteClosed = () => import('../pages/SiteClosed.vue')
const NotFound = () => import('../pages/NotFound.vue')

const routes = [
  {
    path: '/',
    redirect: { name: 'signin' },
  },
  {
    path: '/signin',
    component: SigninBase,
    children: [
      {
        path: '',
        name: 'signin',
        component: Signin,
      },
      {
        path: 'check-email',
        name: 'signin.checkemail',
        component: SigninCheckEmail,
      },
      {
        path: 'verify',
        name: 'signin.verify',
        component: SigninVerify,
      },
      {
        path: 'user-details',
        name: 'signin.userdetails',
        meta: { requiresAuth: true },
        component: SigninUserDetails,
      },
    ],
  },
  {
    path: '/user',
    component: UserBase,
    meta: { requiresAuth: true, requiresOpen: true },
    children: [
      {
        path: '',
        name: 'user',
        component: User,
      },
      {
        path: 'application/:id',
        name: 'user.application',
        component: UserApplication,
      },
      {
        path: 'application/:id/summary',
        name: 'user.application.summary',
        component: UserApplicationSummary,
      },
    ],
  },
  {
    path: '/admin',
    component: AdminBase,
    children: [
      {
        path: '',
        name: 'admin.login',
        component: AdminLogin,
        meta: { requiresAdminAuth: false },
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: AdminDashboard,
        meta: { requiresAdminAuth: true },
      },
      {
        path: 'dashboard/users',
        name: 'admin.users',
        component: AdminUsers,
        meta: { requiresAdminAuth: true },
      },
      {
        path: 'dashboard/settings',
        name: 'admin.settings',
        component: AdminSettings,
        meta: { requiresAdminAuth: true },
      },
    ],
  },
  {
    path: '/page-not-found',
    name: '404',
    component: NotFound,
  },
  {
    path: '/site-closed',
    name: 'site.closed',
    component: SiteClosed,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const settingsService = useService().settings
const userService = useService().user

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresOpen)) {
    const isOpen = await settingsService.isPortalOpenForApplications()
    const isAdminAuthenticated = await userService.isAdmin()
    if (isAdminAuthenticated) {
      next()
      return
    }

    if (!isOpen) {
      next({
        name: 'site.closed',
      })
      return
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = auth.currentUser !== null
    if (!isAuthenticated) {
      console.log('User is not authenticated')
      next({
        name: 'signin',
      })
      return
    }
  }

  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    const isAdminAuthenticated = await userService.isAdmin()
    if (!isAdminAuthenticated) {
      console.log('User is not admin')
      next({
        name: 'admin.login',
      })
      return
    }
  }

  next()
})

export default router
