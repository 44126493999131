import {
  auth,
  storage,
  usersCollection,
  settingsCollection,
} from '../../firebase/firebase'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'

enum Fault {
  noUser = 'No user logged in',
  noApplicationDetails = 'Could not find application details',
  cannotDeleteCauseSent = 'Could not delete application since its already been sent',
  noSettings = 'Application settings invalid. Please contact administrator.',
}

interface Schema {
  name: string
  id: string
  description: string
  icon: string
  color: string
  isSent: boolean
  isDraft: Boolean
  remoteId: string | null
}

interface Application {
  createdDate: Date
  savedDate: Date
  sentDate: Date | null
  schema: string
  uid: string
  values: any
}

const allSchemas: Schema[] = [
  {
    name: 'Stipend',
    id: 'scholarship',
    description:
      'Det kan søkes om reise eller arbeidsstipend dersom man kan dokumentere eller begrunne planer for et bestemt prosjekt, arbeidsprosess eller relevant reise.',
    icon: 'PencilIcon',
    color: 'bg-purple-500',
    isSent: false,
    isDraft: false,
    remoteId: null,
  },
  {
    name: 'Cite des Arts, Paris',
    id: 'cite',
    description:
      'Søknad om opphold på Cité Internationale des Arts i Paris hvor Stiftelsen disponerer 4 atelierer.',
    icon: 'CalendarIcon',
    color: 'bg-yellow-500',
    isSent: false,
    isDraft: false,
    remoteId: null,
  },
  {
    name: 'Støtte til videreutdannelse i utlandet',
    id: 'education',
    description:
      'Kunstnere som ønsker å videreutdanne seg på et universitet eller kunstinstitusjon i utlandet.',
    icon: 'AcademicCapIcon',
    color: 'bg-pink-500',
    isSent: false,
    isDraft: false,
    remoteId: null,
  },
]

export const applicationDetails = (schema: string) => {
  return allSchemas.find((s) => s.id === schema)
}

export const applications = async (): Promise<Schema[]> => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const settingsRef = await settingsCollection.doc('settings').get()
  const settings = settingsRef.data()
  if (!settings) throw Error(Fault.noSettings)

  /**
   * Fetch the current opening period, and get the start of that year.
   * Only fetch applications that are created in that year.
   */
  const startDate = new Date(settings.dateRange.startDate)
  const startOfYear = new Date(startDate.getFullYear(), 0, 1)

  const schemas = allSchemas.map((schema) => schema.id)

  const userApplicationsQuery = await usersCollection
    .doc(userId)
    .collection('applications')
    .where('schema', 'in', schemas)
    .where('createdDate', '>=', startOfYear)
    .orderBy('createdDate', 'desc')
    .get()

  const userApplications = userApplicationsQuery.docs.map((doc) => {
    return { data: doc.data(), id: doc.id }
  })

  const result = allSchemas.map((detail) => {
    const existing = userApplications.find((v) => v.data.schema === detail.id)

    if (existing) {
      detail.isSent = existing.data.sentDate !== undefined
      detail.isDraft = existing.data.savedDate !== undefined
    }

    detail.remoteId = existing?.id || null

    return detail
  })

  return result
}

export const createApplication = async (schema: string): Promise<string> => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const docRef = await usersCollection
    .doc(userId)
    .collection('applications')
    .doc()

  await docRef.set({
    schema: schema,
    createdDate: firebase.default.firestore.FieldValue.serverTimestamp(),
    savedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
    uid: userId,
  })

  return docRef.id
}

export const application = async (id: string): Promise<Application> => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const fetch = await usersCollection
    .doc(userId)
    .collection('applications')
    .doc(id)
    .get()

  const application = fetch.data() as Application | null
  if (!application) throw Error(Fault.noApplicationDetails)
  return application
}

export const saveApplication = async (id: string, values: object) => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  await usersCollection.doc(userId).collection('applications').doc(id).set(
    {
      savedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
      values: values,
    },
    { merge: true },
  )
}

export const submitApplication = async (id: string, values: object) => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const status = {
    date: firebase.default.firestore.FieldValue.serverTimestamp(),
    value: 'new',
  }

  await usersCollection.doc(userId).collection('applications').doc(id).set(
    {
      savedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
      sentDate: firebase.default.firestore.FieldValue.serverTimestamp(),
      status: status,
      values: values,
    },
    { merge: true },
  )
}

export const deleteApplication = async (id: string) => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const doc = usersCollection.doc(userId).collection('applications').doc(id)
  const fetched = await doc.get()
  const data = fetched.data()

  if (!data) return
  if (data.sentDate !== undefined) throw Error(Fault.cannotDeleteCauseSent)

  await doc.delete()
}

export const uploadFile = async (file: File) => {
  const userId = auth.currentUser?.uid
  if (!userId) throw Error(Fault.noUser)

  const blobName = uuidv4()

  const ref = storage.ref()
  const directory = ref.child(`user_upload/${userId}`)
  const blob = directory.child(blobName)

  await blob.put(file)
  const url = await blob.getDownloadURL()
  await blob.updateMetadata({
    customMetadata: {
      originalName: file.name,
    },
  })

  return url
}
