<template>
  <footer class="bg-white">
    <div
      class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
    >
      <div class="flex justify-center space-x-6 md:order-2">
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">{{ item.name }}</span>
          <!--component
                        :is="item.icon"
                        class="h-6 w-6"
                        aria-hidden="true"
                    /-->
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-center md:text-left text-base text-gray-400">
          &copy; 2020 Langaards Stiftelse Ingrid Lindback
          <br />
          <span class="text-xs">
            Tlf:
            <a class="underline" href="tel:+4791882877">+47 918 82 877</a>
            <br />Org nr 938 429 685
          </span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
//import { MailIcon } from '@heroicons/vue/outline'

const navigation = [
  {
    name: 'Email',
    href: 'mailto:post@langaards-stiftelse.no',
    //icon: MailIcon,
  },
]

export default {
  setup() {
    return {
      navigation,
    }
  },
}
</script>
