import {
  applicationsCollection,
  usersCollection,
  auth,
} from '../../firebase/firebase'

import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

enum Fault {
  noUser = 'No user found',
}

interface Query {
  year: number
  schema: string | null
  status: string[]
  categories: string[]
  limit: number | null
  paginateStartAfter: firebase.default.firestore.QueryDocumentSnapshot | null
}

export const applications = async (query: Query): Promise<any[]> => {
  const year = query.year
  const startOfYear = new Date(year, 0, 1)
  const endOfYear = new Date(year, 11, 31)

  let ref = applicationsCollection
  ref = ref.where('sentDate', '>=', startOfYear)
  ref = ref.where('sentDate', '<=', endOfYear)

  if (query.schema) {
    ref = ref.where('schema', '==', query.schema)
  }

  if (query.status.length > 0) {
    ref = ref.where('status.value', 'in', query.status)
  }

  // Fetch everything when user categories filter is applies.
  // Reason is that we do manually filtering in client,
  // so pagination will not work properly if we do not retreive all records.
  if (query.categories.length <= 0) {
    if (query.limit) {
      ref = ref.limit(query.limit)
    }
  }

  ref = ref.orderBy('sentDate')
  ref = ref.startAfter(query.paginateStartAfter)
  const fetch = await ref.get()

  let applications = await Promise.all(
    fetch.docs.map(async (ref) => {
      const data = ref.data()
      const userRef = await usersCollection.doc(data.uid).get()

      return {
        user: userRef.data(),
        remoteId: ref.id,
        ...data,
        ref: ref,
      }
    }),
  )

  if (query.categories.length > 0) {
    applications = applications.filter((application: any) => {
      return query.categories.includes(application.user.artGroup)
    })
  }

  return applications
}

export const setApplicationState = async (
  state: string,
  userId: string,
  applicationId: string,
): Promise<any> => {
  const ref = usersCollection
    .doc(userId)
    .collection('applications')
    .doc(applicationId)

  await ref.update({
    status: {
      value: state,
      date: firebase.default.firestore.FieldValue.serverTimestamp(),
    },
  })

  const userRef = await usersCollection.doc(userId).get()
  const application = await ref.get()

  return {
    user: userRef.data(),
    remoteId: ref.id,
    ...application.data(),
    ref: ref,
  }
}

export const getUserByEmail = async (email: string) => {
  const getUserInfo = firebase.default.functions().httpsCallable('getUserInfo')
  const { data: userInfo } = await getUserInfo({ email: email })

  const result = await usersCollection.doc(userInfo.uid).get()

  return {
    user: result.data(),
    userId: result.id,
  }
}

export const impersonate = async (userId: string) => {
  const impersonate = firebase.default.functions().httpsCallable('impersonate')
  const response = await impersonate({ userId: userId })
  const token = response.data
  await firebase.default.auth().signInWithCustomToken(token)
}
