import firebase from 'firebase'
import { settingsCollection } from '../../firebase/firebase'

interface DateRange {
  startDate: string
  endDate: string
}

interface Settings {
  dateRange: DateRange
}

export const settings = async (): Promise<Settings> => {
  const ref = await settingsCollection.doc('settings').get()
  return ref.data() as Settings
}

export const saveSettings = async (settings: Settings) => {
  await settingsCollection.doc('settings').set(settings, { merge: true })
}

// TODO: Use server timestamp instead of client
export const isPortalOpenForApplications = async (): Promise<boolean> => {
  const { dateRange } = await settings()
  const startDate = new Date(dateRange.startDate)
  const endDate = new Date(dateRange.endDate)
  const nowDate = new Date()

  const isOpen =
    nowDate.getTime() <= endDate.getTime() &&
    nowDate.getTime() >= startDate.getTime()

  return isOpen
}
