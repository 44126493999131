<template>
  <div>
    <main class="min-h-screen">
      <Suspense>
        <router-view />
      </Suspense>
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import Footer from './components/Footer.vue'

export default defineComponent({
  components: {
    Footer,
  },
})
</script>
