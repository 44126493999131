import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import { auth } from './firebase/firebase'

import * as Sentry from '@sentry/vue'

import './assets/style.css'
import 'inter-ui/inter.css'

const app = createApp(App)
app.use(router)

Sentry.init({
  app,
  environment: import.meta.env.DEV ? 'development' : 'production',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'portal.langaards-stiftelse.no', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

app.mount('#app')

auth.onAuthStateChanged((user) => {
  if (user) {
    const data = {
      id: user.uid,
      providerId: user.providerId,
      email: user.email || undefined,
    }
    console.log('Sentry setting user data', data)
    Sentry.setUser(data)
  } else {
    console.log('Sentry removing user data')
    Sentry.setUser(null)
  }
})
